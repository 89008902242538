import React from "react";
import "../styles/global.scss";
import {Link} from "gatsby";
import addToMailchimp from "gatsby-plugin-mailchimp";


const NewsletterPopup = ({children}) => {

    const closePopup = () => {
        let cookie = getCookie("newsletter");
        if (cookie !== "") {

        } else {
            setCookie("newsletter", true, 365);
        }
        document.querySelector(".newsletter__container").classList.add("slidedown");
        setTimeout(() => {
            document.querySelector(".newsletter__container").style.display = "none"
        }, 500)
    };

    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function handleChange(e) {
        value[e.target.id] = e.target.value;
        setValue({...value})
        document.querySelector('#emailpopup').style.borderColor = '#000000';
    }

    const [value, setValue] = React.useState({})

    async function onSubmit(e) {
        e.preventDefault()
        if(value.emailpopup){
            console.log('Starting Mailchimp')
            addToMailchimp(value.emailpopup)
                .then(data => {
                    console.log('Mailchimp Success:')
                    console.log(data)
                    document.querySelector('#mc-embedded-subscribe-form2').style.display = 'none';
                    document.querySelector('#successmessage2').style.display = "block"
                })
                .catch(() => {
                    console.error('Error adding to Mailchimp')
                })
        }
        else{
            document.querySelector('#emailpopup').style.borderColor = '#F05353';
        }
    }

    return (
        <div className="newsletter__container slideup">
            <div className="close" onClick={closePopup}>
                <div></div>
                <div></div>
            </div>
            <h2>GREEN <br/>NEWSLETTER</h2>
            <p className="small subheadline">
                Bleiben Sie auf dem Laufenden! News über
                Solarenergie und Green Lifestyle.
            </p>

            <form onSubmit={onSubmit} method="post" id="mc-embedded-subscribe-form2" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                <div className="input__container">
                    <input className="inputfield smalltext nofill white" type="email" name="EMAIL" id={"emailpopup"} value={value[`emailpopup`] || ``} onChange={handleChange}/>
                    <input type="submit" value="Abonnieren" name="subscribe" id="mc-embedded-subscribe" className="btn outline orangewhite hover-moveup smalltext"/>
                </div>
            </form>
            <div id="successmessage2">
                <p className="smalltext">Zum Newsletter hinzugefügt!</p>
            </div>
            <p className="smalltext newsletter__dsgvo ">Wir verarbeiten Ihre Daten
                gemäß <Link to={"/datenschutz"} className="color-white">Datenschutzbestimmungen</Link></p>
        </div>
    )
}
export default NewsletterPopup
