import React from "react";
import {Link} from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../Layout";
import Head from "../Head";
import LandingVideo from "../LandingVideo";
import HeroText from "../HeroText";
import LandingSection from "../LandingSection";
import LandingLargeSection from "../LandingLargeSection";
import Chart from "../Chart";
import Energierechner from "../Energierechner";
import EniosolMarquee from "../Marquee";
import TestimonialSwiper from "../TestimonialSwiper";
import Marquee from "react-fast-marquee";

const LandingOld = ({page, settings}) => {

    return (
        <Layout landing={true} settings={settings}>
            <Head/>
            <LandingVideo page={page}></LandingVideo>
            <HeroText images={page.images}></HeroText>
            {page.section.map((el, index) => (
                <LandingSection el={el} index={index}></LandingSection>
            ))}
            <LandingLargeSection sectionlarge={page.sectionlarge}></LandingLargeSection>

            <div className="chart__container bgbluegrey">
                <div className="left">
                    <p className="large color-white">
                        Grüne Energie bedeutet
                        nachhaltige Ressourcen
                        richtig zu nutzen, die
                        Umwelt zu schonen und
                        gleichzeitig zu sparen.
                    </p>
                    <p className="large">
                        Ob sich das lohnt? <br/>
                        Die Zahlen sprechen
                        für sich.
                    </p>
                </div>
                <div className="right">
                    <Chart></Chart>
                </div>
            </div>
            <Energierechner></Energierechner>
            <EniosolMarquee outerClass={"fixedHeight bgwhite marqueeWrapper"} innerClass={"color-orange"} content={page.energierechnermarquee}></EniosolMarquee>
            <TestimonialSwiper testimonials={page.testimonials}></TestimonialSwiper>
            <EniosolMarquee outerClass={"fixedHeight bgwhite marqueeWrapper"} innerClass={"color-orange"} content={page.testimonialmarquee}></EniosolMarquee>
            <div className="landing__team__container bgorange">
                <div className="team__headline__container color-white">
                    <p className="large">Das sind Ihre <br/>Ansprechpartner*innen</p>
                    <p className="large team__headline__second">Wir sind <br/>für Sie da</p>
                </div>
                <div className="team__personas__container">
                    {page.ansprechpartner.map(el => (
                        <div className="team__persona">
                            <GatsbyImage  image={el.image.asset.gatsbyImageData} alt={el.title} className="imageround"></GatsbyImage>
                            <p className="small bold">{el.title}</p>
                            <p className="small">{el.jobbezeichnung}</p>
                        </div>
                    ))}
                </div>
                <div className="team__btns">
                    <a href="/about#team" title="TEAM ANSEHEN" className="btn smalltext bold outline black nofill">
                        TEAM ANSEHEN
                    </a>
                    <Link to={"/kontakt"} className="btn smalltext outline bold filled black hover-moveup">KONTAKT AUFNEHMEN</Link>
                </div>
            </div>
            <Marquee gradient={false} className="bgbluegrey marqueeWrapper logomarquee">
                <div className="marqueeText">
                    {page.logos.map(el => (
                        <span className="marqueeText__inner"><GatsbyImage image={el.asset.gatsbyImageData} alt="Logo" className="imageround"></GatsbyImage></span>
                    ))}
                    {page.logos.map(el => (
                        <span className="marqueeText__inner"><GatsbyImage image={el.asset.gatsbyImageData} alt="Logo" className="imageround"></GatsbyImage></span>
                    ))}
                </div>
            </Marquee>
            <div className="landing__info__container bgblack">
                <div className="landing__info__wrapper">
                    {page.blogartikel.map(el => (
                        <div className="landing__info color-white">
                            <p className="large">{el.title}</p>
                            <p className="small">{el.text}</p>
                            <Link to={el.btnslug} className="btn outline white smalltext bold">{el.btntext}</Link>
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    )
}
export default LandingOld
