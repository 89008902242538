import React, {useEffect, useRef} from "react";
import "../styles/global.scss";
import * as d3 from "d3";
import {bgAnimation, chartAnimation, chartPercentageAnimation, slideIn, sunAnimation} from "../animations/Animations";
import {GatsbyImage} from "gatsby-plugin-image";
import {Link} from "gatsby";
import EniosolMarquee from "./Marquee";
import styled from "styled-components";


const ColoredBg = styled.div`
         background-color: ${({color}) => (color ? "#" + color : "unset")};
    
`;

const LandingSection = ({el, index}) => {

    let bgRef = [];
    let sunRef = [];

    bgRef[0] = useRef();
    bgRef[1] = useRef();
    bgRef[2] = useRef();

    sunRef[0] = useRef();
    sunRef[1] = useRef();
    sunRef[2] = useRef();

    useEffect(() => {
        bgRef.map((el, key) => {
            bgAnimation(el.current, key);
        });

        sunRef.map((el, key) => {
            sunAnimation(el.current, key);
        });
    });

    return (
        <div className="section__container__wrapper">
            <div className="section__container">
                <div className="sunsvg__container" ref={sunRef[index]}>
                    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                         viewBox="0 0 49.69 49.69">
                        <g id="Gruppe_75" transform="translate(0.153 0.15)">
                            <g>
                                <g id="Gruppe_74" transform="translate(0 0)">
                                    <path id="Pfad_133" fill="#FFFFFF" d="M24.69-0.15C10.97-0.15-0.15,10.98-0.15,24.7c0,13.72,11.13,24.85,24.85,24.84
				c13.72,0,24.84-11.12,24.84-24.84C49.54,10.98,38.42-0.15,24.69-0.15C24.7-0.15,24.69-0.15,24.69-0.15 M24.69,11.21
				c7.45,0,13.49,6.04,13.49,13.49c0,7.45-6.04,13.49-13.49,13.48c-7.45,0-13.48-6.04-13.49-13.48
				C11.21,17.25,17.24,11.22,24.69,11.21"/>
                                </g>
                            </g>
                        </g>
                    </svg>

                </div>
                <div className="section__right">
                    <GatsbyImage  image={el.image.asset.gatsbyImageData} alt={el.title}></GatsbyImage>
                </div>
                <div className="section__left"  >
                    <ColoredBg className={"coloredBg bg"+index} ref={bgRef[index]} color={el.color}>
                    </ColoredBg>
                    <h6 className="color-white uppercase">{el.title}</h6>
                    <div className="section__bottom">
                        <div className="left">
                            <div className="list__container">
                                <Link className="list__element smalltext hover-moveup" to={"/service/photovoltaik"}><span className="listnr">01</span>Photovoltaik</Link>
                                <Link className="list__element smalltext hover-moveup" to={"/service/batteriespeicher"}><span className="listnr">02</span>Batteriespeicher</Link>
                            </div>
                            <Link className="btn sectionbtn outline black nofill bold smalltext" to={el.btnslug}>{el.btntext}</Link>
                        </div>
                        <div className="right">
                            <p className="small">
                                {el.text}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <EniosolMarquee outerClass={"fixedHeight bgwhite marqueeWrapper"} innerClass={"color-orange"} content={el.marqueetext} amount={10}></EniosolMarquee>
        </div>
    )
}
export default LandingSection
