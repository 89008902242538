import React from "react";
import Layout from "../Layout";
import Head from "../Head";
import Landing from "../landing2024/Landing";
import Schritte from "../landing2024/Schritte";
import Info from "../landing2024/Info";
import Warum from "../landing2024/Warum";
import Referenzen from "../landing2024/Referenzen";
import Newsletter from "../landing2024/Newsletter";
import Kontakt from "../landing2024/Kontakt";

const Landing24 = ({page, settings}) => {

    return (
        <Layout landing={true} settings={settings} landing2024={true}>
            <Head/>
            {page?.pageBuilder?.map((section, index) => {
                if(section._type === 'hero'){
                    return <div key={index} id={index === 1 ? 'start' : ''}><Landing key={index} data={section}/></div>
                } else if(section._type === 'schritte'){
                    return <div key={index} id={index === 1 ? 'start' : ''}><Schritte  data={section}/></div>
                }
                else if(section._type === 'info'){
                    return <div key={index} id={index === 1 ? 'start' : ''}><Info  data={section}/></div>
                }
                else if(section._type === 'warum'){
                    return <div key={index} id={index === 1 ? 'start' : ''}><Warum  data={section}></Warum></div>
                }
                else if(section._type === 'referenzen'){
                    return <div key={index} id={index === 1 ? 'start' : ''}><Referenzen  data={section}/></div>
                }
                else if(section._type === 'newsletter'){
                    return <div key={index} id={index === 1 ? 'start' : ''}><Newsletter  data={section}/></div>
                }
                else if(section._type === 'kontakt'){
                    return <div key={index} id={index === 1 ? 'start' : ''}><Kontakt  data={section}/></div>
                }
            })}
        </Layout>
    )
}
export default Landing24
