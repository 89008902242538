import React from "react";
import {graphql} from "gatsby";
import 'swiper/css';
import "swiper/css/navigation";
import "../styles/global.scss";

import LandingOld from "../components/pageComponent/LandingOld";
import Landing24 from "../components/pageComponent/Landing24";

export const query = graphql`
    {
        allSanityHome2024{
            edges{
                node{
                    active2024
                    pageBuilder {
                        ... on SanityHero {
                            _type
                            image {
                                alt
                                asset {
                                    gatsbyImageData
                                    _type
                                }
                            }
                            button {
                                title
                                link
                            }
                            _rawHeadline
                            subheadline
                            tags
                        }
                        ... on SanityInfo {
                            objectFit
                            _type
                            _rawHeadline
                            _rawText
                            button{
                                title
                                link
                            }
                            image {
                                alt
                                asset {
                                    gatsbyImageData
                                }
                            }
                            services {
                                title
                                link
                            }
                            _rawSubheadline
                            color
                        }
                        ... on SanityKontakt {
                            _type
                            headline
                            telefon
                        }
                        ... on SanityNewsletter {
                            _type
                            _rawText
                            headline
                            subheadline
                        }
                        ... on SanityReferenzen {
                            _type
                            _rawHeadline
                            _rawHeadline2
                            subheadline
                            images {
                                alt
                                asset {
                                    gatsbyImageData
                                }
                            }
                        }
                        ... on SanitySchritte {
                            _type
                            _rawHeadline
                            color
                            services {
                                title
                                link
                            }
                            button{
                                title
                                link
                            }
                            steps {
                                title
                                _rawText
                                image {
                                    alt
                                    asset {
                                        gatsbyImageData
                                    }
                                }
                            }
                        }
                        ... on SanityWarum {
                            _type
                            color
                            button {
                                link
                                title
                            }
                            _rawHeadline
                            steps {
                                title
                                _rawText
                                image {
                                    alt
                                    asset {
                                        gatsbyImageData
                                    }
                                }
                            }
                        }
                    }
                    title
                    seo{
                        seo_title
                    }
                }
            }
        }
  allSanityStartseite {
    edges {
      node {
        title
        video
        section {
          title
          text
          marqueetext
          links
          image {
            asset {
              gatsbyImageData(width: 1920)
            }
          }
          color
          btntext
          btnslug
        }
        image {
          asset {
            gatsbyImageData
          }
        }
        logos{
            asset{
            gatsbyImageData
            }
        }
        images{
            asset{
                gatsbyImageData
            }
        }
        sectionlarge{
            sectiontext1
            sectiontext2
            sectionimage{
            asset{ gatsbyImageData }
            }
            video
        }
       
        energierechnermarquee
        ansprechpartner {
          image {
            asset {
              gatsbyImageData
            }
          }
          jobbezeichnung
          title
        }
        blogartikel {
          text
          btnslug
          btntext
          title
        }
        
        testimonialmarquee
        testimonials{
            title
            ort
            text
            image{ asset { gatsbyImageData}}
        }
      }
    }
  }
  allSanitySettings {
    edges {
      node {
        instalink
        fblink
        linkedinlink
      }
    }
  }
}
`


const IndexPage = ({data}) => {

    const page = data.allSanityStartseite.edges[0].node;
    const page2 = data.allSanityHome2024.edges[0].node;

    if(page2?.active2024){
        return <Landing24 page={page2} settings={data?.allSanitySettings?.edges[0].node}/>
    }else{
        return <LandingOld page={page} settings={data?.allSanitySettings?.edges[0].node}/>
    }
}

export default IndexPage;


/*

 */
