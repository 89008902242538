import React, {useEffect, useRef, useState} from "react";
import "../styles/global.scss";

import Newsletter from "./Newsletter";
import {GatsbyImage} from "gatsby-plugin-image";
import {Link} from "gatsby";
import Vimeo from "@u-wave/react-vimeo";


const LandingVideo = ({page}) => {

    return (
        <div className="landing__section">
            <Newsletter></Newsletter>
            <GatsbyImage image={page.image.asset.gatsbyImageData} alt={page.title}></GatsbyImage>
            <div className="video__container">
                <div className="video-background">
                    <Vimeo
                           video={page.video}
                           autoplay={true} muted={true} loop={true} controls={false} playsInline={true}
                    />
                </div>
            </div>

            <h1 className="color-orange">{page.title}</h1>
            <div className="landing__bottombar">
                <a href={'#energierechner'} className="btn outline white landingbtn smalltext bold">ENERGIEVERGLEICH</a>
                <a href={'#eniosol'} className="landingarrowbtn">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73 73">
                        <g id="Group_103" data-name="Group 103" transform="translate(-924 -975)">
                            <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(924 975)" className="svgoutline">
                                <circle cx="36.5" cy="36.5" r="36.5" stroke="none"/>
                                <circle cx="36.5" cy="36.5" r="35" fill="none"/>
                            </g>
                            <g id="Group_12" data-name="Group 12" transform="translate(655 945.5)">
                                <g id="Group_13" data-name="Group 13">
                                    <line id="Line_1" data-name="Line 1" y2="26.138" transform="translate(305 50)" className="svgarrow"/>
                                    <path id="Path_36" data-name="Path 36" d="M1.061,14.086l11.59,11.59,11.591-11.59" transform="translate(292.349 53)" className="svgarrow"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                </a>
                <Link to={'/service/photovoltaik/'} className="btn white outline landingbtn smalltext bold">PHOTOVOLTAIKANLAGE
                    PLANEN</Link>
            </div>
        </div>
    )
}
export default LandingVideo


/*
<div className="video__container">
                <video autoPlay={true} muted={true} loop={true} src={page.video}></video>
                </div>

                <Vimeo className="react-vimeo-player"
                        video={page.video} background={true} responsive={true}
                        autoplay={true} muted={true} loop={true} controls={false} playsInline={true}
                    />
 */
