import React, {useEffect, useRef} from "react";
import "../styles/global.scss";
import * as d3 from "d3";
import {chartAnimation, chartPercentageAnimation, slideIn} from "../animations/Animations";
import {GatsbyImage} from "gatsby-plugin-image";
import {Link} from "gatsby";

const HeroText = ({images}) => {

    let iconRef1 = useRef();
    let iconRef2 = useRef();
    let iconRef3 = useRef();
    let iconRef4 = useRef();

    useEffect(() => {

        const icons = [
            iconRef1.current,
            iconRef2.current,
            iconRef3.current,
            iconRef4.current
        ];
        slideIn(icons);
    });

    return (
        <div className="eniosoltext__container" id="eniosol">
            <h5>
                Bei eniosol dreht sich alles um smarte,
                <div className="slidein__container"><div className="slidein" ref={iconRef1}><GatsbyImage loading={'eager'} className="introtext__image" image={images[0].asset.gatsbyImageData} alt="Grüne Energie"></GatsbyImage></div></div>
                grüne Energie. Wir möchten mit Ihnen die
                <div className="slidein__container">
                    <div className="slidein" ref={iconRef2}><GatsbyImage loading={'eager'} className="introtext__image"  image={images[1].asset.gatsbyImageData} alt="Bessere Zukunft"></GatsbyImage></div></div>
                Zukunft besser,
                klimafreundlich
                <div className="slidein__container">
                    <div className="slidein" ref={iconRef3}><GatsbyImage loading={'eager'} className="introtext__image"  image={images[2].asset.gatsbyImageData} alt="Klimafreundlich"></GatsbyImage></div></div>
                gestalten und dabei Ihre Strompreise senken und
                <div className="slidein__container">
                    <div className="slidein" ref={iconRef4}><GatsbyImage loading={'eager'} className="introtext__image" image={images[3].asset.gatsbyImageData} alt="Strompreise"></GatsbyImage></div></div>
                stabilisieren.
            </h5>
            <Link to={'/service/photovoltaik/'} className="btn outline landingbtn smalltext bold black">GRÜNE ENERGIE FÜR
                DAHEIM</Link>
        </div>
    )
}
export default HeroText
