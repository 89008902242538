import React, {useEffect, useState} from "react";
import "../styles/global.scss";
import {Link} from "gatsby";
import NewsletterPopup from "./NewsletterPopup";


const Newsletter = ({children}) => {

    const [showModal, setShowModal] = useState(false);

    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    useEffect(() => {

        setTimeout((() => {
                if(getCookie("newsletter") !== ""){
                    //Hier eigentlich nichts, sollte der Newsletter sich wieder entfernen.
                    setShowModal(true);
                }else{
                    setShowModal(true);
                }
            }
        ), 2000);

    })

    return (
        <>
            { showModal ? <NewsletterPopup/> : null }
        </>
    )
}
export default Newsletter
