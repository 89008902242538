import { gsap } from "gsap";
import { ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const slideIn = (element) => {
    let tl = gsap.timeline({
        scrollTrigger: {
            trigger: ".eniosoltext__container",
            start: "top bottom",
            end: "center center",
        }
    })

    tl.from(element, {y: 100, duration: .7, ease: "power1.inOut",stagger: 0.5});
}

export const bgAnimation = (element, i) =>{
    let tl = gsap.timeline({
        scrollTrigger: {
            trigger: ".bg" + i,
            start: "center bottom",
            end: "center center",
            scrub: 1,
        }
    })
    tl.from(element, {opacity: 0})
}

export const sunAnimation = (element, i) =>{
    let tl = gsap.timeline({
        scrollTrigger: {
            trigger: ".bg" + i,
            start: "center bottom",
            end: "center center",
            scrub: 1,
        }
    })
    if(window.innerWidth > 767){
        tl.to(element, {bottom: '80%'})
    }else{
        tl.to(element, {left: '80%'})
    }
}

export const chartAnimation = (element, i) => {
    let tl = gsap.timeline({
        scrollTrigger: {
            trigger: "#infographic__container",
            start: "center bottom",
            end: "center center",
            scrub:1
        }
    });
    tl.from(element,{
        scaleY: 0,
        transformOrigin: '50% 100%',
        //duration: 1,
        ease: "power1.easeOut",
        delay: 0.05*i,
    }, 0);
    /*console.log("chartAnimation");
    console.log(elements);
    console.log(elements.length);
    for(let i = 0; i  < elements.length; i++){
        console.log(elements[i]);
        console.log("innerloop");
        tl.from(elements[i],{
            scaleY: 0,
            transformOrigin: '50% 100%',
            //duration: 1,
            ease: "power1.easeOut",
            delay: 0.05*i,
        }, 0);
    }*/


}

export const chartPercentageAnimation = el =>  {
    let tl = gsap.timeline({
        scrollTrigger: {
            trigger: "#infographic__container",
            start: "center center+=30",
            end: "center center",
            scrub: 1
        }
    });
    tl.from(el,{
        opacity: 0,
        y:  10,
        ease: "power1.easeOut"
    }, 0);
}
