import React, {useEffect, useRef} from "react";
import "../styles/global.scss";
import * as d3 from "d3";
import {chartAnimation, chartPercentageAnimation} from "../animations/Animations";
import Link from "gatsby-link";

const Chart = ({}) => {

    let d3ref = useRef();

    function createChart(){
// set the dimensions and margins of the graph
        const margin = {top: 10, right: 30, bottom: 20, left: 50},
            width = 660 - margin.left - margin.right,
            height = 400 - margin.top - margin.bottom;

        // append the svg object to the body of the page
        const svg = d3.select(d3ref.current)
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        d3.csv("chart.csv").then(function(data) {

            // List of subgroups = header of the csv files = soil condition here
            const subgroups = data.columns.slice(1)

            // List of groups = species here = value of the first column called group -> I show them on the X axis
            const groups = data.map(d => (d.title))

            // Add X axis
            const x = d3.scaleBand()
                .domain(groups)
                .range([0, width-200])
                .padding([0.2])
            svg.append("g")
                .attr('class', 'axis')
                .attr('transform', 'translate(0,35)')
                .call(d3.axisTop(x).tickSize(0));

            // Add Y axis
            var y = d3.scaleLinear()
                .domain([0, 80])
                .range([ height, 0 ]);

            // color palette = one color per subgroup
            var color = d3.scaleOrdinal()
                .domain(subgroups)
                .range(['#ff603b','#D4DAD4','#000000','#b7b783'])

            //stack the data? --> stack per subgroup
            var stackedData = d3.stack()
                .keys(subgroups)
                (data)

            svg.selectAll("mydots")
                .data(subgroups)
                .enter()
                .append("circle")
                .attr("cx", 100)
                .attr("cy", function(d,i){ return 100 + i*40}) // 100 is where the first dot appears. 25 is the distance between dots
                .attr("r", 12)
                .attr("transform", "translate(300,0)")
                .style("fill", function(d){ return color(d)})

            svg.selectAll("mylabels")
                .data(subgroups)
                .enter()
                .append("text")
                .attr("x", 120)
                .attr("y", function(d,i){ return 100 + i*40}) // 100 is where the first dot appears. 25 is the distance between dots
                .text(function(d){ return d})
                .attr("text-anchor", "left")
                .attr("transform", "translate(300,0)")
                .style("alignment-baseline", "middle")


            // Show the bars
            svg.append("g")
                .selectAll("g")
                // Enter in the stack data = loop key per key = group per group
                .data(stackedData)
                .enter().append("g")
                .attr("fill", d => color(d.key))
                .selectAll("rect")
                // enter a second time = loop subgroup per subgroup to add all rectangles
                .data(d => d)
                .enter().append("rect")
                .attr("x", d => x(d.data.title))
                .attr("y", d => height - (y(d[0]) - y(d[1])) + 140)
                .attr("height", d => y(d[0]) - y(d[1]))
                .attr("width",x.bandwidth())
                .attr("ry", 70)
                .attr("class", "bar");

            svg.append("text")
                .text("80%")
                .attr("x", 245)
                .attr("y", "40%")
                .attr("class", "largetext")
                .attr("id", "chartpercentage")


            var barEls = document.getElementsByClassName("bar");
            for(let i = 0; i  < barEls.length; i++) {
                chartAnimation(barEls[i]);
            }
            chartPercentageAnimation(document.querySelector("#chartpercentage"));
        });

    }
    function createMobileChart(){
// set the dimensions and margins of the graph
        const margin = {top: 30, right: 10, bottom: 10, left: 10},
            width = 550 - margin.left - margin.right,
            height = 550 - margin.top - margin.bottom;

        // append the svg object to the body of the page
        const svg = d3.select(d3ref.current)
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        d3.csv("chart.csv").then(function(data) {

            // List of subgroups = header of the csv files = soil condition here
            const subgroups = data.columns.slice(1)

            // List of groups = species here = value of the first column called group -> I show them on the X axis
            const groups = data.map(d => (d.title))

            // Add X axis
            const x = d3.scaleBand()
                .domain(groups)
                .range([0, width])
                .padding([0.2])
            svg.append("g")
                .attr('class', 'axis')
                .attr('transform', 'translate(0,0)')
                .call(d3.axisTop(x).tickSize(0));

            // Add Y axis
            var y = d3.scaleLinear()
                .domain([0, 80])
                .range([ height, 0 ]);

            // color palette = one color per subgroup
            var color = d3.scaleOrdinal()
                .domain(subgroups)
                .range(['#ff603b','#D4DAD4','#000000','#b7b783'])

            //stack the data? --> stack per subgroup
            var stackedData = d3.stack()
                .keys(subgroups)
                (data)

            /*svg.selectAll("mydots")
                .data(subgroups)
                .enter()
                .append("circle")
                .attr("cx", 100)
                .attr("cy", function(d,i){ return 100 + i*40}) // 100 is where the first dot appears. 25 is the distance between dots
                .attr("r", 12)
                .attr("transform", "translate(0,300)")
                .style("fill", function(d){ return color(d)})

            svg.selectAll("mylabels")
                .data(subgroups)
                .enter()
                .append("text")
                .attr("x", 120)
                .attr("y", function(d,i){ return 100 + i*40}) // 100 is where the first dot appears. 25 is the distance between dots
                .text(function(d){ return d})
                .attr("text-anchor", "left")
                .attr("transform", "translate(0,300)")
                .style("alignment-baseline", "middle")*/


            // Show the bars
            svg.append("g")
                .selectAll("g")
                // Enter in the stack data = loop key per key = group per group
                .data(stackedData)
                .enter().append("g")
                .attr("fill", d => color(d.key))
                .selectAll("rect")
                // enter a second time = loop subgroup per subgroup to add all rectangles
                .data(d => d)
                .enter().append("rect")
                .attr("x", d => x(d.data.title))
                .attr("y", d => height - (y(d[0]) - y(d[1])) + 170)
                .attr("height", d => y(d[0]) - y(d[1]))
                .attr("width",x.bandwidth())
                .attr("ry", 100)
                .attr("class", "bar");

            svg.append("text")
                .text("80%")
                .attr("x", 360)
                .attr("y", "40%")
                .attr("class", "largetext")
                .attr("id", "chartpercentage")


            var barEls = document.getElementsByClassName("bar");
            for(let i = 0; i  < barEls.length; i++) {
                chartAnimation(barEls[i]);
            }
            chartPercentageAnimation(document.querySelector("#chartpercentage"));

            document.querySelector('#mobilelegend').style.display = 'flex';
        });

    }

    useEffect(() => {
        if(window.innerWidth > 767){
            createChart();
        }
        else{
            createMobileChart();
        }

    });

    return (
        <div>
            <div id="infographic__container" ref={d3ref}>
                <a href={'#energierechner'} className="btn smalltext black bold nofill outline chartbtn">JETZT VERGLEICHEN</a>
            </div>
            <div id="mobilelegend">
                <div className="entry">
                    <div className="colorball bgorange"></div>
                    <p className="small legendtext">Energiekosten</p>
                </div>
                <div className="entry">
                    <div className="colorball bgwhite"></div>
                    <p className="small legendtext">Ersparnis</p>
                </div>
                <div className="entry">
                    <div className="colorball bggreen"></div>
                    <p className="small legendtext">Gebühren</p>
                </div>
                <div className="entry">
                    <div className="colorball bgblack"></div>
                    <p className="small legendtext">Einspeisung</p>
                </div>
            </div>
        </div>

    )
}
export default Chart
