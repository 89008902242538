import React from "react";
import "../styles/global.scss";
import Marquee from "react-fast-marquee";

const EniosolMarquee = ({outerClass, innerClass, content, amount}) => {
    return (
        <Marquee gradient={false} className={outerClass} speed={40}>
            <div className={"marqueeText " + innerClass}>
                <span className="marqueeText__inner">{content}</span>
                <span className="marqueeText__inner">{content}</span>
                <span className="marqueeText__inner">{content}</span>
                <span className="marqueeText__inner">{content}</span>
                <span className="marqueeText__inner">{content}</span>
                <span className="marqueeText__inner">{content}</span>
                <span className="marqueeText__inner">{content}</span>
                <span className="marqueeText__inner">{content}</span>
                <span className="marqueeText__inner">{content}</span>
                <span className="marqueeText__inner">{content}</span>
            </div>
        </Marquee>
    )
}
export default EniosolMarquee
