import React, {useEffect, useRef} from "react";
import "../styles/global.scss";
import {GatsbyImage} from "gatsby-plugin-image";
import {Link} from "gatsby";
import Vimeo from "@u-wave/react-vimeo";

const LandingLargeSection = ({sectionlarge}) => {

    return (
        <div className="largesection__container bgblack color-white">
            { sectionlarge.video ?
                <div className="video__container sectionvideo">
                    <video playsInline={true} autoPlay={true} muted={true} loop={true} src={sectionlarge.video}></video>
                </div>
                :
                <GatsbyImage alt={"Aus Licht wird Licht"} image={sectionlarge.sectionimage.asset.gatsbyImageData}></GatsbyImage>
            }
            <div className="largesection__text__container">
                <div className="largesection__text">
                    <div className="largesection__text__row first">
                        <div className="largesection__el">
                            <p className="small bold">Smarte Energie <br/> für Ihr Zuhause</p>
                        </div>
                        <div className="list__container largesection__el">
                            <Link className="list__element smalltext color-white" to={"/service/photovoltaik"}><span className="listnr">01</span>Photovoltaik</Link>
                            <Link className="list__element smalltext color-white" to={"/service/batteriespeicher"}><span className="listnr">02</span>Batteriespeicher</Link>
                            <Link className="list__element smalltext color-white" to={"/service/waerme"}><span className="listnr">03</span>Wärme</Link>
                            <Link className="list__element smalltext color-white" to={"/service/e-mobilitaet"}><span className="listnr">04</span>E-Mobilität</Link>
                        </div>
                    </div>
                    <div className="largesection__text__row">
                        <div className="largesection__el">
                            <p className="small largesectiontext">
                                {sectionlarge.sectiontext1}
                            </p>
                            <div className="nav__left__menu">
                                <Link to={'/about'} className="btn outline smalltext bold white">ÜBER ENIOSOL</Link>
                            </div>
                        </div>
                        <div className="list__container largesection__el">
                            <p className="small largesectiontext">
                                {sectionlarge.sectiontext2}
                            </p>
                            <div className="nav__left__menu">
                                <Link to={'/kontakt'} className="btn outline smalltext bold white">KONTAKT</Link>
                            </div>
                        </div>
                        <div className="arrrowbtn__container largesection__el">
                            <Link to={"/service"} className="btn arrowbtn bluegrey smalltext bold filled">
                                <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
                                    <g id="Group_430" data-name="Group 430" transform="translate(-1290 -1780)">
                                        <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(1290 1780)" fill="none" stroke="#fff" stroke-width="3">
                                            <circle cx="26.5" cy="26.5" r="26.5" stroke="none"/>
                                            <circle cx="26.5" cy="26.5" r="25" fill="none"/>
                                        </g>
                                        <g id="Group_23" data-name="Group 23" transform="translate(1302.162 1819.429) rotate(-90)">
                                            <g id="Group_13" data-name="Group 13">
                                                <line id="Line_1" data-name="Line 1" y2="26.138" transform="translate(11.59)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                                <path id="Path_36" data-name="Path 36" d="M0,0,11.59,11.59,23.181,0" transform="translate(0 17.086)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                <p className="small bold largesection">PHOTOVOLTAIK PLANEN</p>
                            </Link>
                            <Link to={"/#energierechner"} className="btn arrowbtn green smalltext bold filled">
                                <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
                                    <g id="Group_430" data-name="Group 430" transform="translate(-1290 -1780)">
                                        <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(1290 1780)" fill="none" stroke="#fff" stroke-width="3">
                                            <circle cx="26.5" cy="26.5" r="26.5" stroke="none"/>
                                            <circle cx="26.5" cy="26.5" r="25" fill="none"/>
                                        </g>
                                        <g id="Group_23" data-name="Group 23" transform="translate(1302.162 1819.429) rotate(-90)">
                                            <g id="Group_13" data-name="Group 13">
                                                <line id="Line_1" data-name="Line 1" y2="26.138" transform="translate(11.59)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                                <path id="Path_36" data-name="Path 36" d="M0,0,11.59,11.59,23.181,0" transform="translate(0 17.086)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                <p className="small bold largesection">PERS. STROMPREISE BERECHNEN</p>
                            </Link>
                            <Link to={"/kontakt"} className="btn arrowbtn pink smalltext bold filled">
                                <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
                                    <g id="Group_430" data-name="Group 430" transform="translate(-1290 -1780)">
                                        <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(1290 1780)" fill="none" stroke="#fff" stroke-width="3">
                                            <circle cx="26.5" cy="26.5" r="26.5" stroke="none"/>
                                            <circle cx="26.5" cy="26.5" r="25" fill="none"/>
                                        </g>
                                        <g id="Group_23" data-name="Group 23" transform="translate(1302.162 1819.429) rotate(-90)">
                                            <g id="Group_13" data-name="Group 13">
                                                <line id="Line_1" data-name="Line 1" y2="26.138" transform="translate(11.59)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                                <path id="Path_36" data-name="Path 36" d="M0,0,11.59,11.59,23.181,0" transform="translate(0 17.086)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                <p className="small bold largesection">TERMIN VEREINBAREN</p>
                            </Link>
                            <Link to={"/blog"} className="btn arrowbtn orange smalltext bold filled last">
                                <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
                                    <g id="Group_430" data-name="Group 430" transform="translate(-1290 -1780)">
                                        <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(1290 1780)" fill="none" stroke="#fff" stroke-width="3">
                                            <circle cx="26.5" cy="26.5" r="26.5" stroke="none"/>
                                            <circle cx="26.5" cy="26.5" r="25" fill="none"/>
                                        </g>
                                        <g id="Group_23" data-name="Group 23" transform="translate(1302.162 1819.429) rotate(-90)">
                                            <g id="Group_13" data-name="Group 13">
                                                <line id="Line_1" data-name="Line 1" y2="26.138" transform="translate(11.59)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                                <path id="Path_36" data-name="Path 36" d="M0,0,11.59,11.59,23.181,0" transform="translate(0 17.086)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                <p className="small bold largesection">GRÜNER BLOG / GRÜNES LEBEN</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LandingLargeSection

/*
<Vimeo
                        video={sectionlarge.video} background={true} responsive={true}
                        autoplay={true} muted={true} loop={true} controls={false} playsInline={true}
                    />
 */
